import React, { useRef, useEffect } from 'react';

// import Button from '@material-ui/core/Button';
// import * as d3 from d3;
// import { useDims } from 'react-dims';

const d3 = require("d3");
d3.selectAll("#chart > *").remove();


export function LineChart({ setOptions, options, selected, data, title, nLines, lineLabels, height, width }) {

    let h = height
    let w = width
    
    let m = { top: 40, bottom: 20, left: 80, right: 60 }
    let colors = ['white', 'darkblue', 'white']
    const ref = useRef(null);
    // console.log(ref.parentElement.offsetWidth)
    
    useEffect(() => {
        d3.select(ref.current)
            .attr("width", w)
            .attr("height", h)
            .attr('id', 'chart')
    }, []); // executed once

    useEffect(() => {
        draw();
    }, [data, selected, options]);


    const parseTime = d3.timeParse('%d/%m/%Y') // "31/03/2021"
    
    const draw = () => {
        // remove old drawings on load !
        d3.selectAll("#chart > *").remove();

        d3.csv(data, function (d) {
            return ({
                date: parseTime(d.dateRep),
                cases: +d.cases,
                deaths: +d.deaths,
                country: d.countriesAndTerritories
            })
        }).then(data => {
            const uniqueValues = [ ...new Set(data.map( item => item.country))]
            setOptions(uniqueValues)
            // USING A REF HERE !!!!
            const svg = d3.select(ref.current);

            let dataset = data.slice().sort((a,b) => d3.descending(a.date, b.date))
            dataset = dataset.filter(d => d.country === selected).slice(0,500)
            dataset = dataset.reverse() // reverse dates, make ascending
            console.log(dataset)

            // remove the aggregated cases
            let casesAggregated = d3.max(dataset, d => d.cases)
            let idx = dataset.indexOf(dataset.find(d => d.cases === casesAggregated))
            dataset.splice(idx, 1)

            let xScale = d3.scaleTime()
                .domain([
                    d3.min(dataset, d => d.date),
                    d3.max(dataset, d => d.date)
                ])
                .range([
                    m.left,
                    w - m.right
                ])

            let yScale = d3.scaleLinear()
                .domain([
                    0,
                    d3.max(dataset, d => d.cases)
                ])
                .range([
                    h - m.bottom,
                    0
                ])

            // let color = d3.scaleOrdinal(d3.schemeCategory10)

            let xAxis = d3.axisBottom()
                .scale(xScale)
                .ticks(w < 400 ? 2 : 8)

            let yAxis = d3.axisLeft()
                .scale(yScale)



            // const svg = d3.select('#chart')
            //     .append('svg')
            //     .attr('width', w)
            //     .attr('height', h)

            svg.append('g')
                .attr('transform', `translate(0,${h - m.bottom})`)
                .call(xAxis)
            svg.append('g')
                .attr('transform', `translate(${m.left},0)`)
                .call(yAxis)


            //  Line drawing animation ###############################
            function transition(path, time) {
                path.transition()
                    .duration(time)
                    .attrTween("stroke-dasharray", tweenDash);
            }
            function tweenDash() {
                var l = this.getTotalLength(),
                    i = d3.interpolateString("0," + l, l + "," + l);
                return function (t) { return i(t); };
            }
            // transition is .call()-ed later
            // ########################################################

            // Line 1
            let lines = d3.line()
                .x(d => xScale(d.date))
                .y(d => yScale(d.cases))

            svg.append('path')
                .datum(dataset)
                .attr('class', 'line')
                .style('fill', 'none')
                .style('stroke', colors[0])
                .attr('d', lines)
                .call((x) => transition(x, 2000))

            // Line 2
            if (nLines === 2) {


                var xDeath = d3.scaleTime()
                    .domain([
                        d3.min(dataset, d => d.date),
                        d3.max(dataset, d => d.date)
                    ])
                    .range([
                        m.left,
                        w - m.right
                    ])

                var yDeath = d3.scaleLinear()
                    .domain([
                        0,
                        d3.max(dataset, d => d.deaths)
                    ])
                    .range([
                        h - m.bottom,
                        0
                    ])

                let yAxisD = d3.axisRight()
                    .scale(yDeath)

                svg.append('g') // deaths
                    .attr('transform', `translate(${w - m.right},0)`)
                    .call(yAxisD)

                let lines2 = d3.line()
                    .x(d => xDeath(d.date))
                    .y(d => yDeath(d.deaths))

                svg.append('path')
                    .datum(dataset)
                    .call((x) => transition(x, 2000))
                    .attr('d', lines2)
                    .style('fill', 'none')
                    .style('stroke', colors[1])
            }

            // Chart TITLE
            svg.append('text')
                .text(title)
                // .attr('x', (m.left + 5))
                // .attr('y', h - m.bottom - 5)
                .attr('x', w-m.right*5)
                .attr('y', 15)
                .style('font-weight', 'bold')
                .style('font-size', '16px')

            // add bubble legends
            for (let i in lineLabels) {

                svg.selectAll('labelCircle')
                    .data([1]) // single value
                    .enter()
                    .append('circle')
                    .attr('r', 16)
                    .attr('cy', h / 2 - 10)
                    .attr('cx', function () {
                        if (+i === 0) {
                            return -30
                        } else {
                            return w + 30
                        }
                    })
                    // .attr('cy', -20)
                    .transition()
                    .duration(1500)
                    .attr('class', 'label')
                    .attr('cx', function () {
                        if (+i === 0) {
                            return m.left / 3
                        } else {
                            return w - m.right / 3
                        }
                    })
                    .attr('stroke', colors[+i])
                    .attr('fill', colors[+i])


                svg.append('text')
                    .attr('x', function () {
                        if (+i === 0) {
                            return -30
                        } else {
                            return w + 30
                        }
                    })
                    .attr('y', h / 2 - 7)
                    .transition()
                    .duration(1500)
                    // .attr('fill', colors[i])
                    .attr('x', function () {
                        if (+i === 0) {
                            return m.left / 3
                        } else {
                            return w - m.right / 3
                        }
                    })
                    .text(`${lineLabels[i]}`)
                    .attr('text-anchor', 'middle')
                    .attr('font-size', '9px')
                    .attr('font-weight', 'bold')
                    .attr('fill', colors[+i + 1])
            }
        })
    }

    return (
        <>
            <svg
                ref={ref}
            ></svg>
        </>
    )
}

