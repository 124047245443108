

export const cards = [
    {
        title: 'A simple Step-by-Step Installation Guide for TensorFlow & TensorFlow Object Detection API',
        text: "Installing TensorFlow and the TensorFlow object detection API can be tedious and time consuming. This guide will guide you through the whole setup in not time.",
        imgSource: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*kG1zVGS1OfmY82XUcQMJTA.png",
        extLink: "https://towardsdatascience.com/a-simple-step-by-step-installation-guide-for-tensorflow-tensorflow-object-detection-api-c1660d4ae533",
    },
    {
        title: 'Why does Regularization actually work?',
        text: "Deconstructing the mathematical concept of regularization in machine learning.",
        imgSource: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*4TFXEaAsj2na47kbt4s7Ag.jpeg",
        extLink: "https://towardsdatascience.com/regularization-996a4967984a",
    },
    {
        title: 'How to Use the Empirical Distribution of Your Data',
        text: "Improve simulation modeling through random variables drawn from the empirical distribution of your data.",
        imgSource: "https://miro.medium.com/max/700/0*D6Nk1GhEvu9MlISx",
        extLink: "https://towardsdatascience.com/empirical-distribution-6af5142eed09",
    },
    {
        title: 'Fully Mastering Fisher’s Exact Test for A/B Testing',
        text: "An in-depth analysis of Fisher's exact test, theory and Python code.",
        imgSource: "https://miro.medium.com/max/700/0*frkAvty7Fh9QZYbK",
        extLink: "https://towardsdatascience.com/fishers-exact-fb49432e55b5",
    },
    {
        title: 'A Simple Guide to Gradient Descent',
        text: "Illustrating the algorithm in a multiple linear regression example in Python",
        imgSource: "https://miro.medium.com/max/700/1*0Ej7RPQUh0mG6ngc33fc6w.jpeg",
        extLink: "https://towardsdatascience.com/gradient-descent-f7458de38365#51f4-c27559a7b0bc",
    },
    {
        title: 'Leveraging analytic functions in SQL for faster data extraction',
        text: "Advanced SQL",
        imgSource: "https://miro.medium.com/v2/resize:fit:720/0*-VKQp5pc49tmtJEZ",
        extLink: "https://towardsdatascience.com/sql-window-functions-939ed24c9752",
    },
    {
        title: 'Benchmark your Machine Learning Models using Cloud Based AutoML',
        text: "Using Google's Cloud Based AutoML for Prediction",
        imgSource: "https://miro.medium.com/max/500/0*EYVamUPYkOP1WPI8",
        extLink: "https://towardsdatascience.com/benchmark-your-models-6ef942e2683f",
    },
    {
        title: 'Successfully Navigating through Principal Component Analysis',
        text: "From Dimensionality Reduction to Image Compression",
        imgSource: "https://miro.medium.com/max/500/0*72uMLdtJqk1bDaOP",
        extLink: "https://towardsdatascience.com/principal-component-analysis-93b96b35ddc",
    },
    {
        title: 'Data Classification Algorithms',
        text: "Supervised Machine Learning at its best",
        imgSource: "https://miro.medium.com/max/700/1*Rf9EIOBC2HGZceHF2sdEoQ.jpeg",
        extLink: "https://towardsdatascience.com/supervised-learning-b662cb97df7",
    },
    {
        title: 'Graph Analysis',
        text: "Find well connected users in a Graph",
        imgSource: "https://miro.medium.com/max/700/0*FH_i9HYpRL_imiGM",
        extLink: "https://towardsdatascience.com/identify-well-connected-users-in-a-social-network-19ea8dd50b16",
    },
    {
        title: 'The Elon Musk Twitter Graph',
        text: "Graph Mining with just a few lines of code",
        imgSource: "https://miro.medium.com/max/500/1*bRitzwd6u_Lrvs_kuQwWAw.png",
        extLink: "https://towardsdatascience.com/graph-mining-2dd6c4c1f1b1",
    },
    {
        title: 'Proper Model Selection through Cross Validation',
        text: "Cross validation is state of the art.",
        imgSource: "https://miro.medium.com/max/500/0*hsDO1UoT7IzlngmG",
        extLink: "https://towardsdatascience.com/cross-validation-7c0163460ea0",
    },
    {
        title: 'Training, Validating and Testing ',
        text: "Why Proper Model Selection is Essential",
        imgSource: "https://miro.medium.com/max/1000/0*-GqYSwsR_xBKPned",
        extLink: "https://towardsdatascience.com/train-test-split-c3eed34f763b",
    },
    {
        title: 'Regex for your Day-to-Day Tasks',
        text: "Enjoy the power of regular expression.",
        imgSource: "https://miro.medium.com/max/700/1*on6sFsl59vHpIuxftdHxkg.jpeg",
        extLink: "https://towardsdatascience.com/mastering-regular-expressions-for-your-day-to-day-tasks-b01385aeea56",
    },
    {
        title: 'Data Exploration 101 with Pandas',
        text: "A quick guide to work with Pandas.",
        imgSource: "https://miro.medium.com/max/500/0*Bjc5BlTGJV-La0hI",
        extLink: "https://towardsdatascience.com/data-exploration-101-with-pandas-e059d0661313",
    },
    {
        title: 'Text Similarity Measurement',
        text: "Machine Learning algorithm to evaluated unstructured data",
        imgSource: "https://miro.medium.com/max/700/0*t8y3derHrjPDBqSN",
        extLink: "https://towardsdatascience.com/find-text-similarities-with-your-own-machine-learning-algorithm-7ceda78f9710",
    },
    {
        title: 'A comparison: Julia, R and Python',
        text: "Data Science in 2021",
        imgSource: "https://miro.medium.com/max/700/0*xmSgEOTCJB-gnjrQ",
        extLink: "https://towardsdatascience.com/julia-r-and-python-7cd50c2b0fe4",
    },
]
