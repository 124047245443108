import { createMuiTheme } from "@material-ui/core/styles";

export function themeGenerate(darkLight){ 
  
  return createMuiTheme({
  palette: {
    type: darkLight,
    primary: {
      // main: "#282c34",
      main: "#B3A369",
    },
    secondary: {
      main: "#003057",
    },
    background: {
      paper: "#8E8B76",
    },
  },

  color: {
    mainFont: "#cdcdcd",
  },

})
}
