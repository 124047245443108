import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: 300,
    height: '100%',
    // minWidth: 300,
  },
  media: {
    height: 140,
  },
  buttons : {
    bottom :'2px',
    display: 'flex'
  },
  CardContent : {
    flexGrow : 1,
  }
});

export function MediaCard( props ) {
  const classes = useStyles();

  return (
    <Card className={classes.root} key={Math.random()}>
      <CardActionArea
        href={props.extLink}
        target="_blank"
      >
        <CardMedia
          className={classes.media}
          image={props.imgSource}
          title="Title Image"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.text}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions
        className={classes.buttons}
      >
        <Button size="small" color="primary"
          href="mailto:"
        >
          Share
        </Button>
        <Button size="small" color="primary"
          href={props.extLink}
          target="_blank"
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}
