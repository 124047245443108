import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';



const useStyles = makeStyles((theme) => ({
    root : {
        position:'fixed',
        bottom: 20,
        right: 10,
    },
}))

const action = (
    <Button 
        color="primary"
    >
    Close
    </Button>
)

export function CookieBanner(props) {
    
    const classes = useStyles()
    const [open, setOpen] = useState(true)

    const clickHandler = () => {
        setOpen(false)
        const current = new Date();
        const nextYear = new Date();

        nextYear.setFullYear(current.getFullYear() + 1);
        // console.log(nextYear)
        props.setCookie("consent", true, {
            expires: nextYear,
        })
        props.setCookie("SameSite", 'Strict')
    }

// open && 
    return (open && props.currentConsent===false) ? (
        
        <SnackbarContent
            className={classes.root}
            message={"This pages uses basic cookies to store your preferences."}
            action={action}
            onClick={clickHandler}
        >
        </SnackbarContent>
    ) : <Fragment />;

}