import {
  Paper,
  Box,
  Grid,
  Select,
  Link,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { MainFeaturedPost } from '../Components/'
import {
  CookieBanner,
  cards,
  MediaCard,
  LineChart
} from '../Components'

import { useEffect, useState } from "react";
import dataCSV from '../data/data.csv'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  paperStyle: {
    margin: '10px',
    background: 'primary',
  },
  paperContainer: {
    padding: '20px 10px',
    minHeight: 100,
    textAlign: 'center',
  },
  selector: {
    textAlign: 'right',
  },
  remark : {
    fontSize : '10px',
  },
  options :{
    cursor:'pointer',
  }
})

export function Landing(props) {

  const classes = useStyles();

  // const ary = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15] // old: just "more to come" elements 

  const [data, setData] = useState(null)
  useEffect(() => {
    // setData('https://opendata.ecdc.europa.eu/covid19/nationalcasedeath_eueea_daily_ei/csv/data.csv')
    setData(dataCSV)
  }, [])

  // options -> will store all possible options (when first set: setOptions)
  // selected -> will store one selected value
  const [options, setOptions] = useState(['Austria'])
  const [selected, setSelected] = useState('Austria')

  const countryClick = (e) => {
    setSelected(e.target.value)
  }

  useEffect(() => {
    // console.log(selected)
  }, [selected])

  const [size, setSize] = useState({'height':window.innerHeight, 'width' : window.innerWidth})

  useEffect(() => {
    setSize({
      'height' : window.innerHeight,
      'width' : window.innerWidth
    })
  }, [])

  return (
    <>
      <MainFeaturedPost
        post={{
          setNavigation: props.setNavigation,
          title: 'Data and Science',
          decription: 'Pretty cool text',
          image: 'https://source.unsplash.com/collection/935527/1900x600', //https://source.unsplash.com/1600x900/?nature,mountain
          linkText: 'by Guenter Roehrich',
        }}
      />

      <Grid container className={classes.root} spacing={4} >
        <Grid item xl={12} md={12} sm={12}>
          <Paper className={classes.paperContainer} elevation={4}>
            <Box>
              <p><strong>Monthly updated</strong> global Covid-19 figures. The chart shows new infections as well as deaths per country.
                Default selection is Austria. The data is retrieved daily from <Link href="https://opendata.ecdc.europa.eu/covid19/nationalcasedeath_eueea_daily_ei/csv/data.csv">opendata</Link> at 9.00 UTC.</p>
                <code>#Covid-19 #Cases #EU #World #Pandemic</code>
              <p className={classes.remark}>Please note, this page may not yet be fully responsive for your mobile device.</p>
            </Box>
          </Paper>
        </Grid>
        <Grid item xl={12} md={12} sm={12}>
          <Paper className={classes.paperContainer} elevation={4}>
            <Grid item>
              <Box>
              <h2>Covid-19: Daily Infections and Deaths</h2>
              </Box>
            </Grid>
            <Grid item className={classes.selector} >
            <Box>
              <Select
                variant={'outlined'}
                onChange={countryClick}
                value={selected}
              >
                {options.map((country) => {
                  return (
                    <option
                      className={classes.options}
                      value={country}
                      key={Math.random()}
                      options={options} // adding options as prop because of a bug. sometimes options load to slowly and then nothing is selectable in the dropdown.
                    >
                      {country}
                    </option>
                  )
                })}
              </Select>
              </Box>
            </Grid>
            <Grid item>
              <LineChart
                setOptions={setOptions}
                selected={selected}
                height={size.height*0.3}
                width={size.width*0.8}
                data={data}
                nLines={2}
                lineLabels={['Cases', 'Deaths']}
                title={'Covid-19: Infections and Deaths'}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {/* Placeholder blog items like under the "knowledge page" */}
      
      {/* <Grid container className={classes.gridContainer} key={Math.random()}
      spacing={4}>
        <Grid item xl={12} md={12} sm={12}>
          <Paper className={classes.paperContainer} m={'auto'}>
            <Box>
              <h1>Readings and Knowledge</h1>
            </Box>
          </Paper>
        </Grid>
        {cards.map((card) => {
          return (
            <Grid item xs={12} sm={6} md={3} key={Math.random()}>
              <MediaCard {...card} item className={classes.card} key={Math.random()} />
            </Grid>
          )
        })}
      </Grid> */}

      {/* 
      <Grid container spacing={3}
        justify='center'
        alignItems='center'>
        { ary.map( () => 
          <Grid xs={12} sm={5} md={3} lg={2} item className={classes.root} key={Math.random()}>
            <Paper elevation={3}>
              <Box p={3}>More to come...</Box>
            </Paper>
          </Grid>
        )}
      </Grid> */}

      <CookieBanner
        currentConsent={props.currentConsent}
        setConsentGiven={props.setConsentGiven}
        setCookie={props.setCookie}
      />
    </>
  )
};
