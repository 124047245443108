import {
  Grid,
  // Container,
  createStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  MediaCard,
  cards,
} from '../Components'

const useStyles = makeStyles((theme) => {
  createStyles({
    root: {
      backgroundColor: theme.color,
      display: 'flex',
    },
    card: {
      marginLeft: theme.spacing(4),
    },
    cardGrid: {
      flexGrow: 1,
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  })
})


export function Blogs(props) {

  const classes = useStyles();

  return (
    <>
      <main>
        <Grid container className={classes.cardGrid} key={Math.random()}>
          <Grid container spacing={2} direction="row">
            {cards.map((card) => {
              return (
                <Grid item xs={12} sm={6} md={3} key={Math.random()}>
                  <MediaCard {...card} item className={classes.card} key={Math.random()} />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </main>
    </>
  )
};
