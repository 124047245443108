import React, { useEffect, useState, Fragment } from "react";
import './App.css';
import { useCookies } from 'react-cookie';
import { 
  Landing,
  Blogs,
  MachineLearning,
 } from './views';

import { Navigation } from './AppLayout'
import {CookieBanner} from './Components'

import { MuiThemeProvider } from "@material-ui/core";
import { themeGenerate } from "./theme";

export function App() {

  const [navigation, setNavigation] = useState('Home')
  const [content, setContent] = useState(null);
  const [pageHeader, setPageHeader] = useState(null)

  const [cookies, setCookie] = useCookies(['themePref', 'consent', 'secure', {'SameSite':'Strict'}]);

  let startingConsent, startingColor = 'light'
  
  // cookie starting condition
  cookies.themePref ?  startingColor = cookies.themePref : startingColor = 'light'
  cookies.consent ? startingConsent = cookies.consent :  startingConsent = false

  const [consentGiven, setConsentGiven] = useState(startingConsent)
  const [colorTheme, setColorTheme] = useState( startingColor )


  // if navigation or consentGiven changes (probably due to the setNavigation, setConsentGiven function)
  // then the effect is executed and sets the content type
  useEffect(() =>{
    if (navigation === 'Home'){
      setContent(<Landing 
        setNavigation={ setNavigation }   // setNavigation is required to allow link to blog
      />) 
      setPageHeader('Home')
    } else {// (navigation === 'Blogs' | navigation === 'Knowledge') { // old name Blogs, new name Knowledge
      setContent(<Blogs />)
      setPageHeader('Readings')
    //  else if (navigation === 'Machine Learning'){
    //   setContent(<MachineLearning setNavigation={ setNavigation } />) // setNavigation is required to allow link to blog
    //   setPageHeader('Machine Learning Algorithms') 
    }
  }, [navigation, consentGiven]); // removed setCookies - makes no sense here
  

  let theme  = themeGenerate(colorTheme)

  return content ? (
    <MuiThemeProvider theme={ theme }>
      <Navigation 
        setNavigation={ setNavigation } 
        element={ pageHeader }
        setColorTheme={ setColorTheme }
        setCookie={ setCookie }
        currentColor={ colorTheme }
      >
        {content} 
        {/* Comes from the useEffect */}
      </Navigation>

      {/* Create Cookie Banner on ALL pages. Removed when clicked. */}
      <CookieBanner
        currentConsent={ consentGiven }
        setConsentGiven={ setConsentGiven }
        setCookie={ setCookie }
      />

    </MuiThemeProvider>
  ) : (
  <Fragment />)
  
}

// export default App;
